import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {languages, pdfPrefixes, vehicleModels} from "../availableData";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  readonly vehicleModels = vehicleModels;
  selection : currentSelection;
  readonly languages = languages;


  constructor(private route: ActivatedRoute, @Inject(LOCALE_ID) locale: string) {
    let selectedLanguage = locale.substring(0, locale.indexOf('-')).toUpperCase();
    const vehicleModel = route.snapshot.data['vehicleModel'];
    let language = 'EN';
    if (this.languages[selectedLanguage]) {
      language = selectedLanguage;
    } else {
      console.log("No locales for [".concat(locale, "] available. Defaulting to english."))
    }
    this.selection = {
      language: language,
      model: vehicleModel ? vehicleModel : vehicleModels["smart #1"],
      pdfFilename: '',
      pdfSource: ''
    }
    this.updatePdfDetails()
  }

  ngOnInit(): void {

  }

  onVehicleModelChange(selectedValue: any) {
    this.selection.model = selectedValue.target.value;
    this.updatePdfDetails();
  }
  onLanguageChange(selectedValue: any) {
    this.selection.language = selectedValue.target.value;
    this.updatePdfDetails();
  }

  updatePdfDetails() {
    this.selection.pdfFilename = pdfPrefixes[this.selection.model]+this.selection.language+'.pdf'
    this.selection.pdfSource ='../../assets/pdfs/'+this.selection.model+'/'+encodeURIComponent(this.selection.pdfFilename)
  }
}

interface currentSelection {
  model: string,
  language: string,
  pdfFilename: string,
  pdfSource: string
}


