export const languages : {[propKey: string]: string} = {
  'EN': 'English',
  'BG': 'Български',
  'CS': 'Čeština',
  'DA': 'Dansk',
  'DE': 'Deutsch',
  'EL': 'Ελληνικά',
  'ES': 'Español',
  'ET': 'Eesti keel',
  'FI': 'suomi',
  'FR': 'Francais',
  'HR': 'Hrvatska',
  'HU': 'Magyar',
  'IT': 'Italiano',
  'LT': 'Lietuva',
  'LV': 'Latvian',
  'NL': 'Nederlands',
  'NO': 'Norsk',
  'PL': 'Polski',
  'PT': 'Português',
  'RO': 'Română',
  'SK': 'Slovenská',
  'SL': 'slovenščina',
  'SV': 'Svenska'
}

export const  vehicleModels = {
  "smart #1" : "1",
  "smart #3" : "3"
}

export const pdfPrefixes: { [propKey: string]: string } = {
  "1" : "smart_#1__SUV_2022_5d_Electric_",
  "3" : "smart_#3__Hatchback_2023_5d_Electric_"
}
