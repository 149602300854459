<div class="headerContainer">
  <span><img src="assets/images/smart_Logo.png" alt="smart-Logo" height="30px"></span>
  <span>
         <span class="dropdownContainer" >
            <select name="vehicleModel" class="dropdown" [(ngModel)]="selection.model" (change)="onVehicleModelChange($event)">
                <option class="overlay" *ngFor="let model of vehicleModels | keyvalue" [value]="model.value">
                    {{model.key}}
                </option>
            </select>
        </span>
        <span class="dropdownContainer">
            <select name="language" class="dropdown" [(ngModel)]="selection.language" (change)="onLanguageChange($event)">
                <option class="overlay" *ngFor="let language of languages | keyvalue" [value]="language.key" >
                    {{language.value}}
                </option>
            </select>
        </span>
        <a [download]="selection.pdfFilename" target="_blank" [href]="selection.pdfSource">
            <img src="assets/images/dwnld-icon.jpg" alt="download-icon" height="25px" id="downloadIcon">
        </a>
    </span>
</div>
<pdf-viewer [src]="selection.pdfSource"
            [render-text]="true"
            [original-size]="false"
            [show-all]="true"
            [fit-to-page]="true"
            [autoresize]="true"
></pdf-viewer>
